<script setup lang="ts">
const props = defineProps<{
    autoFocus?: boolean
    disabled?: boolean
    modelValue?: boolean
}>()

const checked = defineModel<boolean>()

const radioEl = ref<HTMLInputElement | null>(null)
onMounted(() => {
    if (props.autoFocus) {
        radioEl.value?.focus()
    }
})

function toggle() {
    checked.value = !checked.value
}
</script>

<template>
    <input
        ref="radioEl"
        type="radio"
        :checked="checked"
        :disabled="disabled"
        b="1px black-900"
        cursor-pointer
        :class="{
            'text-black-900': checked,
            'text-black-900 bg-white hover:bg-gray-80': !checked,
        }"
        class="form-radio focus:ring-1 focus:ring-gray-120"
        @change="toggle"
    >
</template>
